<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="所属车队">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="驾驶员姓名">
          <el-input v-model="dataForm.name" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="dataForm.contactPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号码">
          <el-input v-model="dataForm.cardNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose" v-model="dataForm.status" @change="getDataList" size="small" filterable
                     clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否车队长">
          <el-select class="item-choose" v-model="dataForm.captain" @change="getDataList" size="small" filterable
                     clearable placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.yes_no"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button @click="downLoad()" type="success" v-if="newAuth('driver:openId:export')">导出OpenID</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
                :data="dataList" border size="small" v-loading="dataListLoading"
                @selection-change="selectionChangeHandle" style="width: 100%;">
        <af-table-column prop="name" label="驾驶员姓名">
        </af-table-column>
        <el-table-column prop="contactPhone" label="联系电话" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <!--        <af-table-column prop="nowCity" label="现居住城市">-->
        <!--        </af-table-column>-->
        <af-table-column prop="address" label="现居地址">
        </af-table-column>
        <af-table-column prop="nativeCity" label="籍贯">
        </af-table-column>
        <el-table-column prop="cardNo" label="身份证号码" width="140" :show-overflow-tooltip="true">
        </el-table-column>
        <af-table-column label="身份证照片" prop="" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, 'IdCard')" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </af-table-column>
        <af-table-column label="驾驶证照片" prop="" width="70">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, 'driverCard')" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </af-table-column>
        <el-table-column prop="proofType" width="60" :formatter="dictConvert" label="准驾类型">
        </el-table-column>
        <el-table-column prop="isHaveVehicle" width="50" :formatter="yesNo" label="自带车">
        </el-table-column>
        <af-table-column prop="urgentPhone" label="紧急联系电话">
        </af-table-column>
        <af-table-column prop="motorcadeName" label="所属车队">
        </af-table-column>
        <el-table-column prop="captain" width="50" :formatter="yesNo" label="车队长">
        </el-table-column>
        <af-table-column prop="entryDate" label="入职日期">
        </af-table-column>
        <af-table-column prop="baseWage" label="基本工资">
        </af-table-column>
        <af-table-column prop="dutyDay" label="出勤天数">
        </af-table-column>
        <af-table-column prop="fullDutyFee" label="全勤奖">
        </af-table-column>
        <af-table-column prop="status" label="状态" width="40" fixed="right">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
            <el-tag v-else size="small" type="success">启用</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" width="60" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle('view', scope.row.id)">详情
              </el-button>
              <el-button v-if=" scope.row.wxOpenId" type="text" size="small"
                         @click="bindWxOpenIdHandle(scope.row, false)">解绑微信openId
              </el-button>
              <el-button v-else type="text" size="small"
                         @click="bindWxOpenIdHandle(scope.row, true)">绑定微信openId
              </el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
                         @click="addOrUpdateHandle('edit', scope.row.id)">修改
              </el-button>
              <el-button v-if="isAuth('sys:user:delete')" type="text" size="small"
                         @click="disableOrEnable(scope.row)">{{ scope.row.status === 2 ? '启用' : '禁用' }}
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!--  绑定微信openId  -->
    <bind-wx-openId v-if="bindWxOpenIdVisible" ref="BindWxOpenId" @refreshDataList="getDataList"></bind-wx-openId>
    <!-- 弹窗修改 -->
    <detail v-if="detailVisible" ref="detail"></detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import AddOrUpdate from './details/driver-add-or-update'
import BindWxOpenId from './details/driver-bind-wx-openId'
import detail from './details/driver-detail'
import viewImage from '@/views/commonPop/viewImage'

export default {
  data () {
    return {
      dataForm: {
        name: null,
        contactPhone: null,
        cardNo: null,
        motorcadeName: null,
        status: null,
        captain: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      bindWxOpenIdVisible: false,
      detailVisible: false,
      idCardTypeList: [5000, 5010],
      proofTypeList: [5001]
    }
  },
  components: {
    AddOrUpdate,
    BindWxOpenId,
    detail,
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row, todo) {
      if (todo === 'IdCard') {
        this.$refs.viewPhoto.init(row.id, this.idCardTypeList)
      } else {
        this.$refs.viewPhoto.init(row.id, this.proofTypeList)
      }
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driver/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status,
          'cardNo': this.dataForm.cardNo,
          'motorcadeName': this.dataForm.motorcadeName,
          'contactPhone': this.dataForm.contactPhone,
          'captain': this.dataForm.captain
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 绑定微信openId
    bindWxOpenIdHandle (row, bind) {
      if (bind) {
        this.bindWxOpenIdVisible = true
        this.$nextTick(() => {
          this.$refs.BindWxOpenId.init(row)
        })
      } else {
        this.$confirm('确定对【' + row.name + '】进行【解绑微信openId】操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/wx/bindOpenId'),
            method: 'post',
            data: this.$http.adornData({
              'id': row.id,
              'wxOpenId': null
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '解绑成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        })
      }
    },
    downLoad () {
      this.$http({
        url: this.$http.adornUrl('/export/driverOpenIdExport'), // 只导出启用状态的信息
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'name': this.dataForm.name,
          'cardNo': this.dataForm.cardNo,
          'motorcadeName': this.dataForm.motorcadeName,
          'contactPhone': this.dataForm.contactPhone,
          'page': this.pageIndex,
          'limit': this.pageSize
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '司机OpenID列表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/driver/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
